//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "MenuInfo",
    inject: ["showLoading", "hideLoading"],
    data() {
        return {
            title: '',
            model: '',
            loading: false,
            menuForm: {
                menuName: '',
                parentId: 0,
                url: '',
                pagePath: '',
                permission: '',
                icon: '',
                type: 0,
                orderNum: '',
                available: '',
                description: ''
            },
            formRole: {

            }
        }
    },
    computed: {
        visible: function () {
            return this.$store.state["menuManagement"].showInfoDrawer;
        },
        activeMenu: function () {
            return this.$store.state["menuManagement"].activeMenu;
        },
        parentOfChild: function () {
            return this.$store.state["menuManagement"].parentOfChild;
        },
    },
    watch: {
        "visible": {
            handler: function () {
                this.setFormRoles();
                if (this.activeMenu) {
                    this.title = `修改菜单-${this.activeMenu.menuName}`;
                    this.model = 'modify';
                    this.menuForm = this.activeMenu;
                }else {
                    this.title = '添加菜单'
                    this.model = 'add';
                    if (this.parentOfChild) {
                        this.title = `添加子菜单-${this.parentOfChild.menuName}`
                        this.menuForm.parentId = this.parentOfChild.id;
                    }
                }
            },
            deep: true
        },
        "menuForm.type": {
            handler: function () {
                this.setFormRoles();
            },
            deep: true
        }
    },
    methods: {
        closeDrawer: function () {
            this.$store.dispatch("menuManagement/closeInfoDrawer");
            this.menuForm = {
                menuName: '',
                parentId: 0,
                url: '',
                pagePath: '',
                permission: '',
                icon: '',
                type: 0,
                orderNum: '',
                available: '',
                description: ''
            };
        },
        setFormRoles: function () {
            this.formRole = {};
            this.$forceUpdate();
            this.$nextTick(() => {
                this.formRole = {
                    menuName: [
                        { required: true, message: '请输入菜单名称', trigger: 'blur' }
                    ],
                    url: [
                        { required: this.menuForm.type === 0, message: '请输入菜单Url', trigger: 'blur' }
                    ],
                    permission: [
                        { required: this.menuForm.type === 1, message: '请输入权限编码', trigger: 'blur' }
                    ]
                }
                this.$forceUpdate();
            })
        },
        save: function () {
            let dispatchType = "";
            switch (this.model) {
                case "add":
                    dispatchType = 'menuManagement/addNewMenu';
                    break;
                case "modify":
                    dispatchType = 'menuManagement/updateMenu';
                    break;
            }
            this.$refs.form.validate().then(() => {
                this.loading = true;
                this.$store.dispatch(dispatchType, this.menuForm).then(resp => {
                    this.loading = false;
                    if (resp.code === 200) {
                        this.closeDrawer();
                        this.showLoading("正在刷新数据");
                        this.$store.dispatch("menuManagement/getMenuList").then(() => {
                            this.hideLoading();
                        })
                    }
                })
            }).catch()

        }
    }
}
