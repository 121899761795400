//
//
//
//
//
//
//

import List from "./list";
import MenuInfo from "./menu-info";
export default {
    name: "MenuManagement",
    components: {MenuInfo, List},
    inject: ["hideLoading"],
    mounted() {
        this.hideLoading();
    }
}
