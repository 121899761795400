//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageHeader from "@/components/page-header";
import HeaderFormItem from "@/components/page-header/header-form-item";

export default {
    name: "list",
    inject: ["showLoading", "hideLoading"],
    components: {HeaderFormItem, PageHeader},
    computed: {
        tableData: function () {
            return this.$store.state["menuManagement"].menuList;
        }
    },
    data() {
        return {
            query: {
                menuName: '',
            },
            ids: [],
        }
    },
    watch: {
        "query": {
            handler: function () {
                this.$store.dispatch("menuManagement/setQuery", this.query);
            },
            deep: true
        }
    },
    methods: {
        getList: function () {
            this.showLoading("正在刷新数据");
            this.$store.dispatch("menuManagement/getMenuList").then(() => {
                this.hideLoading();
            });
        },
        add: function () {
            this.$store.dispatch("menuManagement/openInfoDrawer", null);
        },
        deleteRow: function (row) {
            this.$confirm("确定删除这条记录吗？", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: "warning"
            }).then(() => {
                this.ids = [];
                this.getChildrenId([row])
                this.showLoading("等待服务器响应");
                this.$store.dispatch("menuManagement/deleteMenu", this.ids).then(resp => {
                    this.hideLoading();
                    this.$notify({
                        title: resp.code,
                        message: resp.msg,
                        type: resp.code === 200 ? "success" : 'error',
                        position: "bottom-right"
                    })
                    if (resp.code === 200) {
                        this.getList();
                    }
                })
            }).catch(() => {
                this.$notify({
                    title: "提示",
                    message: "已取消删除",
                    type: "info",
                    position: "bottom-right"
                })
            })

        },
        updateRow: function (row) {
            this.$store.dispatch("menuManagement/openInfoDrawer", row);
        },
        addChildren: function (row) {
            this.$store.dispatch("menuManagement/setParentOfChild", row);
            this.$store.dispatch("menuManagement/openInfoDrawer", null);
        },

        /**
         * 递归获取id
         */
        getChildrenId(val) {
            val.forEach(item => {
                this.ids.push(item.id);
                if (item.childrenList && item.childrenList.length > 0) {
                    this.getChildrenId(item.childrenList);
                }
            })
        }
    },
    created() {
        this.getList();
    }
}
